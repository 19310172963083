exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adventure-jsx": () => import("./../../../src/pages/adventure.jsx" /* webpackChunkName: "component---src-pages-adventure-jsx" */),
  "component---src-pages-airdrop-early-access-email-jsx": () => import("./../../../src/pages/airdrop/earlyAccess/email.jsx" /* webpackChunkName: "component---src-pages-airdrop-early-access-email-jsx" */),
  "component---src-pages-airdrop-early-access-index-jsx": () => import("./../../../src/pages/airdrop/earlyAccess/index.jsx" /* webpackChunkName: "component---src-pages-airdrop-early-access-index-jsx" */),
  "component---src-pages-blocked-tokenomics-jsx": () => import("./../../../src/pages/blocked/tokenomics.jsx" /* webpackChunkName: "component---src-pages-blocked-tokenomics-jsx" */),
  "component---src-pages-bounty-hunters-jsx": () => import("./../../../src/pages/bounty-hunters.jsx" /* webpackChunkName: "component---src-pages-bounty-hunters-jsx" */),
  "component---src-pages-download-demo-jsx": () => import("./../../../src/pages/download/demo.jsx" /* webpackChunkName: "component---src-pages-download-demo-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-game-jsx": () => import("./../../../src/pages/game.jsx" /* webpackChunkName: "component---src-pages-game-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nodes-jsx": () => import("./../../../src/pages/nodes.jsx" /* webpackChunkName: "component---src-pages-nodes-jsx" */),
  "component---src-pages-pages-airdrop-a-jsx": () => import("./../../../src/pages/pages/airdrop-a.jsx" /* webpackChunkName: "component---src-pages-pages-airdrop-a-jsx" */),
  "component---src-pages-pages-airdrop-jsx": () => import("./../../../src/pages/pages/airdrop.jsx" /* webpackChunkName: "component---src-pages-pages-airdrop-jsx" */),
  "component---src-pages-pages-game-jsx": () => import("./../../../src/pages/pages/game.jsx" /* webpackChunkName: "component---src-pages-pages-game-jsx" */),
  "component---src-pages-pirates-jsx": () => import("./../../../src/pages/pirates.jsx" /* webpackChunkName: "component---src-pages-pirates-jsx" */),
  "component---src-pages-token-jsx": () => import("./../../../src/pages/token.jsx" /* webpackChunkName: "component---src-pages-token-jsx" */),
  "component---src-pages-tokenomics-jsx": () => import("./../../../src/pages/tokenomics.jsx" /* webpackChunkName: "component---src-pages-tokenomics-jsx" */),
  "component---src-pages-tycoon-jsx": () => import("./../../../src/pages/tycoon.jsx" /* webpackChunkName: "component---src-pages-tycoon-jsx" */),
  "component---src-pages-wallet-jsx": () => import("./../../../src/pages/wallet.jsx" /* webpackChunkName: "component---src-pages-wallet-jsx" */),
  "component---src-templates-team-member-template-team-member-template-jsx": () => import("./../../../src/templates/teamMemberTemplate/TeamMemberTemplate.jsx" /* webpackChunkName: "component---src-templates-team-member-template-team-member-template-jsx" */)
}

